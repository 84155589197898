import React, { lazy, Suspense, useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { Route, Routes } from "react-router";
import useAppDispatch from "hooks/useAppDispatch";
import useAppSelector from "hooks/useAppSelector";
import { fetchUserData, refreshTokens } from "store/auth/thunks";
import { selectAuthenticatedUser } from "store/auth";
import token from "services/auth/tokenService";
import {
  clearSnackbar,
  selectIsSnackbarOpen,
  selectSnackbarAction,
  selectSnackbarMessage,
} from "store/snackbar";
import {
  GLOBAL_SUPPLIERS_AND_BRANDS,
  CATALOG_PRODUCTS,
  FORWARD_SLASH,
  LOGIN,
  ONBOARDING,
  PROCUREMENTS_AND_SUPPLIERS_PRODUCTS,
  SUB_ROUTE_GLOBAL_SUPPLIERS_AND_BRANDS,
  SUB_ROUTE_CATALOG,
  SUB_ROUTE_CATALOG_PRODUCTS,
  SUB_ROUTE_EDIT_CATALOG_PRODUCTS,
  SUB_ROUTE_PROCUREMENTS_AND_SUPPLIERS,
  SUB_ROUTE_PROCUREMENTS_AND_SUPPLIERS_PRODUCTS,
  SUB_ROUTE_REGISTER,
  SUB_ROUTE_STOCK_TAKING,
  SUB_ROUTE_CUSTOMERS,
  SUB_ROUTE_CUSTOMERS_PICK_AND_PACK,
  CUSTOMERS_PICK_AND_PACK,
  SUB_ROUTE_PROCUREMENT_AND_SUPPLIER,
  SUB_ROUTE_PROCUREMENT_AND_SUPPLIER_FEED,
  SUB_ROUTE_PROCUREMENT,
  PROCUREMENT_AND_SUPPLIER,
  SUB_ROUTE_PROCUREMENTS_AND_SUPPLIERS_CATEGORIES,
  SUB_ROUTE_EDIT_CUSTOMERS_ORDER,
  SUB_ROUTE_CUSTOMERS_ORDERS,
  SUB_ROUTE_EDIT_PROCUREMENTS_AND_SUPPLIERS_CATEGORIES,
  SUB_ROUTE_ADD_PROCUREMENTS_AND_SUPPLIERS_CATEGORIES,
  SUB_ROUTE_CATALOG_PRODUCTS_IMAGES,
  SUB_ROUTE_CATEGORY,
  SUB_ROUTE_PROCUREMENT_AND_ORDERS,
  SUB_ROUTE_CATEGORY_MARKUP,
  SUB_ROUTE_PROCUREMENT_IMAGE_URLS,
  COMPANIES,
  COMPANY_PREFERENCES,
  SUB_ROUTE_MISCELLANEOUS_FACEBOOK_PRODUCT_TAXONOMIES,
  SUB_ROUTE_MISCELLANEOUS_GOOGLE_PRODUCT_TAXONOMIES,
  SUB_ROUTE_PREFERENCES_FACEBOOK_PRODUCT_TAXONOMIES_SELECTION,
  SUB_ROUTE_INVENTORY_TRANSACTIONS,
  SUB_ROUTE_CATALOG_INVENTORY_LOCATIONS,
  SUB_ROUTE_INVENTORYLOCATIONTYPE,
  SUB_ROUTE_MISCELLANEOUS_Notifcation,
  SUB_ROUTE_PROCUREMENTS_AND_SUPPLIERS_FILE_URLS,
  SUB_ROUTE_BULK3_DESCRIPTION,
} from "constants/urls";
import SimpleSnackbar from "components/SimpleSnackbar";
import PageNotFoundComponent from "components/PageNotFound";
import { Box, CircularProgress } from "@mui/material";
import { LicenseInfo } from "@mui/x-license";
import LazyImportFallback from "components/lazy-loading/LazyImportFallback";

const LoginPage = lazy(() => import("pages/login-page"));
const MainPage = lazy(() => import("pages/main-page"));
const OverviewPage = lazy(() => import("pages/main-page/overview-page"));
const OnboardingPage = lazy(() => import("pages/onboarding-page"));
const ProcurementsAndSuppliersPage = lazy(
  () => import("pages/main-page/procurements-suppliers-page"),
);
const Register = lazy(() => import("pages/onboarding-page/parts/Register"));
const OnboardingStart = lazy(
  () => import("pages/onboarding-page/parts/OnboardingStart"),
);

const CatalogPage = lazy(() => import("pages/main-page/catalog-page"));
const EditCatalogProductPage = lazy(
  () => import("pages/main-page/edit-catalog-product-page"),
);
const Login = lazy(() => import("pages/login-page/parts/Login"));
const ResetPassword = lazy(
  () => import("pages/login-page/parts/ResetPassword"),
);
const NewPassword = lazy(() => import("pages/login-page/parts/NewPassword"));
const StockTakingPage = lazy(() => import("pages/main-page/stock-taking-page"));
const PickAndPackByOrderPage = lazy(
  () => import("pages/main-page/pick-pack-order-page"),
);
const Supplier = lazy(
  () => import("pages/main-page/procurement-supplierList-page"),
);
const ProcurementCategoriesPage = lazy(
  () => import("pages/main-page/procurement-categories-page"),
);
const EditCustomersOrderPage = lazy(
  () => import("pages/main-page/edit-customers-order-page"),
);
const CustomersOrdersPage = lazy(
  () => import("pages/main-page/customers-orders"),
);
const AddProcurementCategoryPage = lazy(
  () =>
    import(
      "pages/main-page/add-edit-procurement-category-page/add-procurement-category-page"
    ),
);
const EditProcurementCategoryPage = lazy(
  () =>
    import(
      "pages/main-page/add-edit-procurement-category-page/edit-procurement-category-page"
    ),
);
const CatalogImagesPage = lazy(() => import("pages/main-page/catalog-images"));
const Category = lazy(() => import("pages/main-page/catalog-category"));
const Orders = lazy(() => import("pages/main-page/procurement-orders-page"));
const Markup = lazy(() => import("pages/main-page/catalag-markup"));
const ProcurementsAndSupplierFeedsPage = lazy(
  () => import("pages/main-page/procurement-supplier-feeds"),
);
const ProcurementImages = lazy(
  () => import("pages/main-page/procurement_images"),
);
const Companies = lazy(
  () => import("pages/main-page/procurements-companies-page"),
);
const Taxonomies = lazy(() => import("pages/main-page/taxonomies"));
const FacebookProductTaxonomies = lazy(
  () => import("pages/main-page/procurements-facebook-page"),
);
const FacebookProductTaxonomiesSelection = lazy(
  () => import("pages/main-page/procurement-facebook-selection"),
);
const InventoryTransactionPage = lazy(
  () => import("pages/main-page/inventory-transactions"),
);
const InventoryLocationType = lazy(
  () => import("pages/main-page/catalog-inventorylocationtype"),
);
const InventoryLocations = lazy(
  () => import("pages/main-page/catalog-inventory-locations"),
);
const NotificationsSystem = lazy(
  () => import("pages/main-page/notification-system/part/index"),
);
const ProductCatalogFileUrlsPage = lazy(
  () => import("pages/main-page/catalog-file-urls"),
);
const CatalogBulkDescription = lazy(
  () => import("pages/main-page/catalog-bulk-description"),
);

const App = (): JSX.Element => {
  const [initialLoad, setInitialLoad] = useState(true);
  const user = useAppSelector(selectAuthenticatedUser);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const snackbarOpen = useAppSelector(selectIsSnackbarOpen);
  const snackbarMessage = useAppSelector(selectSnackbarMessage);
  const snackbarAction = useAppSelector(selectSnackbarAction);

  const MUIkey =
    "2d6fc005701a1418eaa43c7d8313db82Tz0xMDE1NzQsRT0xNzYyOTM3NTY2MDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI=";
  LicenseInfo.setLicenseKey(MUIkey.toString());

  useEffect(() => {
    const fetchUser = async () => {
      try {
        await dispatch(refreshTokens(token.service().getRefreshToken()));
        await dispatch(fetchUserData());
      } catch {
        if (!location.pathname.startsWith("/login")) {
          navigate("/onboarding");
        }
      }
      setInitialLoad(false);
    };
    fetchUser();
  }, [dispatch]);

  if (initialLoad) {
    return (
      <div style={{ display: "flex", width: "100%" }}>
        <CircularProgress size={64} />
      </div>
    );
  }

  return (
    <Box>
      <Suspense fallback={LazyImportFallback}>
        <Routes>
          <Route
            path={FORWARD_SLASH}
            element={user ? <MainPage /> : <Navigate to={ONBOARDING} replace />}
          >
            {/* Overview - page */}
            <Route index element={<OverviewPage />} />

            {/* My Product Catalog - page */}
            <Route
              path={SUB_ROUTE_CATALOG}
              element={<Navigate to={CATALOG_PRODUCTS} replace />}
            />
            <Route
              path={SUB_ROUTE_CATALOG_PRODUCTS}
              element={<CatalogPage />}
            />
            <Route
              path={SUB_ROUTE_EDIT_CATALOG_PRODUCTS}
              element={<EditCatalogProductPage />}
            />
            <Route
              path={SUB_ROUTE_BULK3_DESCRIPTION}
              element={<CatalogBulkDescription />}
            />
            <Route
              path={SUB_ROUTE_STOCK_TAKING}
              element={<StockTakingPage />}
            />
            <Route path={SUB_ROUTE_CATEGORY} element={<Category />} />
            <Route path={SUB_ROUTE_CATEGORY_MARKUP} element={<Markup />} />
            <Route
              path={SUB_ROUTE_MISCELLANEOUS_GOOGLE_PRODUCT_TAXONOMIES}
              element={<Taxonomies />}
            />
            <Route path={SUB_ROUTE_CATEGORY} element={<Category />} />
            <Route path={SUB_ROUTE_CATEGORY_MARKUP} element={<Markup />} />
            <Route
              path={SUB_ROUTE_MISCELLANEOUS_GOOGLE_PRODUCT_TAXONOMIES}
              element={<Taxonomies />}
            />
            <Route
              path={SUB_ROUTE_CATALOG_INVENTORY_LOCATIONS}
              element={<InventoryLocations />}
            />

            {/* My Suppliers - page */}
            <Route
              path={SUB_ROUTE_PROCUREMENTS_AND_SUPPLIERS}
              element={
                <Navigate to={PROCUREMENTS_AND_SUPPLIERS_PRODUCTS} replace />
              }
            />
            <Route
              path={SUB_ROUTE_PROCUREMENTS_AND_SUPPLIERS_CATEGORIES}
              element={<ProcurementCategoriesPage />}
            />
            <Route
              path={SUB_ROUTE_EDIT_PROCUREMENTS_AND_SUPPLIERS_CATEGORIES}
              element={<EditProcurementCategoryPage />}
            />
            <Route
              path={SUB_ROUTE_ADD_PROCUREMENTS_AND_SUPPLIERS_CATEGORIES}
              element={<AddProcurementCategoryPage />}
            />

            <Route
              path={SUB_ROUTE_PROCUREMENTS_AND_SUPPLIERS_PRODUCTS}
              element={<ProcurementsAndSuppliersPage />}
            />
            <Route
              path={SUB_ROUTE_PROCUREMENTS_AND_SUPPLIERS_FILE_URLS}
              element={<ProductCatalogFileUrlsPage />}
            />

            {/* Customers - page */}
            <Route
              path={SUB_ROUTE_CATALOG}
              element={<Navigate to={CATALOG_PRODUCTS} replace />}
            />
            <Route
              path={SUB_ROUTE_CATALOG_PRODUCTS}
              element={<CatalogPage />}
            />
            <Route
              path={SUB_ROUTE_EDIT_CATALOG_PRODUCTS}
              element={<EditCatalogProductPage />}
            />
            <Route
              path={SUB_ROUTE_CATALOG_PRODUCTS_IMAGES}
              element={<CatalogImagesPage />}
            />
            <Route
              path={SUB_ROUTE_PROCUREMENT_IMAGE_URLS}
              element={<ProcurementImages />}
            />
            <Route
              path={SUB_ROUTE_INVENTORY_TRANSACTIONS}
              element={<InventoryTransactionPage />}
            />
            <Route
              path={SUB_ROUTE_INVENTORYLOCATIONTYPE}
              element={<InventoryLocationType />}
            />
            <Route
              path={SUB_ROUTE_GLOBAL_SUPPLIERS_AND_BRANDS}
              element={<Navigate to={GLOBAL_SUPPLIERS_AND_BRANDS} replace />}
            />
            <Route
              path={SUB_ROUTE_CUSTOMERS}
              element={<Navigate to={CUSTOMERS_PICK_AND_PACK} replace />}
            />
            <Route
              path={SUB_ROUTE_CUSTOMERS_PICK_AND_PACK}
              element={<PickAndPackByOrderPage />}
            />

            {/* Companies - page */}
            <Route path={COMPANIES} element={<Companies />} />
            <Route path={COMPANY_PREFERENCES} element={<Companies />} />

            {/* Users - page */}

            {/* Preferences - page */}

            <Route
              path={SUB_ROUTE_PREFERENCES_FACEBOOK_PRODUCT_TAXONOMIES_SELECTION}
              element={<FacebookProductTaxonomiesSelection />}
            />

            {/* Miscellaneous - page */}
            <Route
              path={SUB_ROUTE_MISCELLANEOUS_FACEBOOK_PRODUCT_TAXONOMIES}
              element={<FacebookProductTaxonomies />}
            />
            <Route
              path={SUB_ROUTE_MISCELLANEOUS_Notifcation}
              element={<NotificationsSystem />}
            />

            {/* All Suppliers & Brands - page */}
            <Route
              path={SUB_ROUTE_GLOBAL_SUPPLIERS_AND_BRANDS}
              element={<Navigate to={GLOBAL_SUPPLIERS_AND_BRANDS} replace />}
            />
            <Route
              path={SUB_ROUTE_CUSTOMERS_ORDERS}
              element={<CustomersOrdersPage />}
            />
            <Route
              path={SUB_ROUTE_EDIT_CUSTOMERS_ORDER}
              element={<EditCustomersOrderPage />}
            />
            <Route
              path={SUB_ROUTE_PROCUREMENT}
              element={<Navigate to={PROCUREMENT_AND_SUPPLIER} replace />}
            />
            <Route
              path={SUB_ROUTE_PROCUREMENT_AND_SUPPLIER}
              element={<Supplier />}
            />
            <Route
              path={SUB_ROUTE_PROCUREMENT_AND_ORDERS}
              element={<Orders />}
            />
            <Route
              path={SUB_ROUTE_PROCUREMENT_AND_SUPPLIER_FEED}
              element={<ProcurementsAndSupplierFeedsPage />}
            />
          </Route>

          <Route path={LOGIN} element={<LoginPage />}>
            <Route index element={<Login />} />
            <Route path="reset-password" element={<ResetPassword />} />
            <Route path="new-password" element={<NewPassword />} />
          </Route>

          <Route path={ONBOARDING} element={<OnboardingPage />}>
            <Route index element={<OnboardingStart />} />
            <Route path={SUB_ROUTE_REGISTER} element={<Register />} />
          </Route>
          <Route path="*" element={<PageNotFoundComponent />} />
        </Routes>
      </Suspense>

      <SimpleSnackbar
        open={snackbarOpen!}
        toggleSnackbar={() => dispatch(clearSnackbar())}
        message={snackbarMessage}
        snackbarAction={snackbarAction}
      />
    </Box>
  );
};

export default App;
