import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  IBulkDescriptionArgsUpdate,
  IBulkDescriptionBase,
  IProduct,
  IProductFilters,
} from "./interfaces";
import catalogBulkDescription from "services/catalog-bulk-description";

export const fetchBulkDescriptionProducts = createAsyncThunk<
  any,
  URLSearchParams
>("products/fetchFilteredProducts", async (filters) => {
  const result = await catalogBulkDescription
    .service()
    .fetchFilteredProducts(filters);
  return result;
});

export const addNewBulkDescription = createAsyncThunk(
  "products/addNewBulkDescription",
  async (params: IBulkDescriptionBase) => {
    const result = await catalogBulkDescription
      .service()
      .addBulkDescription(params);
    return result;
  },
);

export const updateBulkDescription = createAsyncThunk(
  "products/updateBulkDescription",
  async ({
    id,
    params,
  }: {
    id: number;
    params: IBulkDescriptionArgsUpdate;
  }) => {
    const result = await catalogBulkDescription
      .service()
      .updateBulkDescription(id, params);
    return result;
  },
);

export const updateOnlyBulkEditFixedDescription = createAsyncThunk(
  "products/updateBulkDescription",
  async ({ id, params }: { id: number; params: IProduct }) => {
    const result = await catalogBulkDescription
      .service()
      .updateOnlyBulkEditFixedDescription(id, params);
    return result;
  },
);
