import axios from "axios";
import config from "../../config";
import commonHeaders from "services/http/headers";
import { Mockable } from "..";
import { endpoint } from "constants/endpoint";
import {
  IBulkDescriptionBase,
  IProduct,
  IBulkDescriptionArgsResponse,
  IBulkDescriptionArgsUpdate,
  IProductResponse,
} from "store/catalog-bulk-description/interfaces";

export interface IBulkDescriptionService {
  fetchFilteredProducts(
    filters: URLSearchParams,
    headers?: Record<string, string>,
  ): Promise<IProduct>;
  addBulkDescription(
    params: IBulkDescriptionBase,
    headers?: Record<string, string>,
  ): Promise<IBulkDescriptionArgsResponse>;
  updateBulkDescription(
    id: number,
    params: IBulkDescriptionArgsUpdate,
    headers?: Record<string, string>,
  ): Promise<IBulkDescriptionArgsResponse>;
  updateOnlyBulkEditFixedDescription(
    id: number,
    params: IProduct,
    headers?: Record<string, string>,
  ): Promise<IProductResponse>;
}

export const fetchFilteredProductsURL =
  endpoint.catalog_products_bulk3_description;
export const addBulkDescriptionURL = endpoint.product_description;
export const updateBulkDescriptionURL = endpoint.product_description;
export const updateOnlyBulkEditFixedDescriptionURL =
  endpoint.catalog_products_bulk3_description;
export default new Mockable<IBulkDescriptionService>({
  async fetchFilteredProducts(filters, headers) {
    try {
      const response = await axios.get(
        `${config.api}${fetchFilteredProductsURL}`,
        {
          params: filters,
          headers: headers ?? commonHeaders(),
        },
      );
      if (response.status !== 200) {
        throw new Error(`Request failed with status code ${response.status}`);
      }
      return response.data;
    } catch (error: any) {
      throw error;
    }
  },

  async addBulkDescription(
    params: IBulkDescriptionBase,
    headers: Record<string, string>,
  ): Promise<IBulkDescriptionArgsResponse> {
    try {
      const response = await axios.post(
        `${config.api}${addBulkDescriptionURL}`,
        {
          method: "POST",
          headers: headers ?? commonHeaders(),
          data: JSON.stringify(params),
        },
      );
      if (response.status !== 201) {
        throw new Error(`Request failed with status code ${response.status}`);
      }
      return response.data;
    } catch (error: any) {
      throw error;
    }
  },

  async updateBulkDescription(
    id: number,
    params: IBulkDescriptionArgsUpdate,
    headers: Record<string, string>,
  ): Promise<IBulkDescriptionArgsResponse> {
    try {
      const response = await axios.put(
        `${config.api}${updateBulkDescriptionURL}${id}`,
        {
          method: "PUT",
          headers: headers ?? commonHeaders(),
          data: JSON.stringify(params),
        },
      );
      if (response.status !== 200) {
        throw new Error(`Request failed with status code ${response.status}`);
      }
      return response.data;
    } catch (error: any) {
      throw error;
    }
  },

  async updateOnlyBulkEditFixedDescription(
    id: number,
    params: IProduct,
    headers: Record<string, string>,
  ): Promise<IProductResponse> {
    try {
      const response = await axios.patch(
        `${config.api}${updateOnlyBulkEditFixedDescriptionURL}${id}`,
        {
          method: "PATCH",
          headers: headers ?? commonHeaders(),
          data: JSON.stringify(params),
        },
      );
      if (response.status !== 200) {
        throw new Error(`Request failed with status code ${response.status}`);
      }
      return response.data;
    } catch (error: any) {
      throw error;
    }
  },
});
